<template>
	<c-dialog ref="dialog" title="选择机台" width="550" @resolve="resolve">
		<c-table ref="table" height="400">
			<c-table-column
				type="selector"
				width="20"
				:multiple="!!multiple"
                :selected="row => row.data.checked"
			></c-table-column>

			<c-table-column
				label="机台名称"
				width="200"
				name="machine_name"
			></c-table-column>


			<c-table-column
				label="备注"
				width="300"
				name="remark"
			>
				<template #default="{data}">
					<span v-if="data.remark">{{data.remark}}</span>
					<span v-else>--</span>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		
		methods: {
			open() {
				this.$refs.dialog.open();
				this.$refs.table.load({
					url: '/dic/machine/list',
					data: {}
				});
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择机台'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>