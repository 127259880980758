<template>
	<page>
		<module>
			<c-form ref="form" column="2" unit-width="95%" style="max-width:1100px">
				<c-title>订单信息</c-title>
				<c-detail column="2">
					<c-detail-item label="订单号" colspan="2">
					    {{this.order_info.order_number}}
					</c-detail-item>
					<c-detail-item label="下单客户">
						{{order_info.customer_name}}
					</c-detail-item>
					<c-detail-item label="客户货号">
					     {{order_info.customer_product_code}}
					</c-detail-item>
					<c-detail-item label="产品名称">
					     {{order_info.product_name}}
					</c-detail-item>
<!--					<c-detail-item label="产品编码">
					    {{order_info.product_code}}
					</c-detail-item>-->
					<c-detail-item label="品牌名称">
					    {{order_info.brand_name}}
					</c-detail-item>
					<c-detail-item label="品牌LOGO">
						<c-file v-if="order_info.brand_logo" media :src="order_info.brand_logo" style="width:30px;height:30px;"></c-file>
					</c-detail-item>
					<c-detail-item v-if="order_info.production_json" label="订单生产要求">
						<div v-for="(item, i) in order_info.production_json" :key="i">{{i}}：{{item}}</div>
					</c-detail-item>
					<c-detail-item v-if="order_info.product_json" label="产品生产参数">
						<div v-for="(item, i) in order_info.product_json" :key="i">{{i}}：{{item}}</div>
					</c-detail-item>
					<c-detail-item v-if="order_info.product_remark" label="产品生产说明"  colspan="2">
						{{order_info.product_remark}}
					</c-detail-item>
				</c-detail>
				<c-title>派工信息</c-title>
<!--				<c-form-item label="班组" required>
					<div class="flex-center">
						&lt;!&ndash; <c-input name="team_name"  maxlength="50" readonly disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="selectTeam">选择班组</c-button> &ndash;&gt;
						<c-select name="team_name" options="TEAM_LIST" @change="teamChangeOption($event)"></c-select>
					</div>
				</c-form-item>-->
				<c-form-item label="负责员工" required>
					<c-input type="hidden" v-model="person_responsible_id" name="person_responsible_id"></c-input>
					<div class="flex-center">
						<c-input name="person_responsible_name" v-model="person_responsible_name" maxlength="50" readonly disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="selectResponsiblePerson">选择员工</c-button>
					</div>
				</c-form-item>
<!--				<c-form-item label="机台" required>
					<div class="flex-center">
						&lt;!&ndash; <c-input name="machine_name"  maxlength="50" readonly disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="selectMachine">选择机台</c-button> &ndash;&gt;
						<c-select name="machine_name" options="MACHINE_LIST" ></c-select>
					</div>
				</c-form-item>-->
				<c-form-item label="质检员">
					<c-input type="hidden" name="quality_id"></c-input>
					<div class="flex-center">
						<c-input name="quality_name"  maxlength="50" readonly disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="selectQuality">选择员工</c-button>
					</div>
				</c-form-item>
				<c-form-item label="任务交期">
					<c-datetime type="date" name="schedule_date" v-model="order_info.delivery_schedule_date"></c-datetime>
				</c-form-item>
				<c-form-item label="计划时间">
					<c-datetime type="date" start-name="task_begin_date" end-name="task_end_date" range></c-datetime>
				</c-form-item>
				<c-title>派工产品明细</c-title>
				<!--展示订单待派工的产品列表-->
				<module enable-flex>
					<c-table ref="product_table" :paging="false" border>
						<c-table-column
							label="颜色"
							width="120"
							name="product_color"
							v-if="systemConfig.system_size_enable=='ON'"
							:row-span="({data},list)=>{
								var span_count = 0;
								list.forEach(item => {
									if(item.data.product_color == data.product_color){
										span_count ++;
									}
								});
								return span_count;
							}"
						>
						</c-table-column>
						<c-table-column
							label="颜色"
							width="120"
							name="product_spec"
							v-if="systemConfig.system_size_enable=='ON'"
							:row-span="({data},list)=>{
								var span_count = 0;
								list.forEach(item => {
									if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
										span_count ++;
									}
								});
								return span_count;
							}"
						>
						</c-table-column>
						<c-table-column
							label="尺码"
							width="120"
							name="product_size"
							v-if="systemConfig.system_size_enable=='ON'"
						>
						</c-table-column>
						<c-table-column
							label="待派工"
							width="90"
							name="product_count"
						>
						</c-table-column>

						<c-table-column
							label="派工数量"
							width="120"
							name="task_count"
						>
							<template v-slot="{data}">
								<c-input type="number" v-model="data.task_count" :range="[0,data.product_count]"></c-input>
							</template>
						</c-table-column>
					</c-table>
				</module>
			</c-form>

			<template slot="button">
				<c-button color="main" size="form" @click="submit()">提交保存</c-button>
			</template>
		</module>
		<!--选择班组-->
		<team-selector ref="teamSelector" @resolve="selectTeamHandle"></team-selector>
		<!--选择负责人-->
		<responsible-selector ref="responsibleSelector" @resolve="selectResponsibleHandle"></responsible-selector>
		<!--选择质检员-->
		<responsible-selector ref="qualitySelector" @resolve="selectQualityHandle"></responsible-selector>
		<!--选择机台-->
		<machine-selector ref="machineSelector" @resolve="selectMachineHandle"></machine-selector>
	</page>
</template>

<script>
import teamSelector from '@/components/service/team_selector.vue'  //班组选择
import responsibleSelector from '@/components/service/sys_user_selector.vue'
import qualitySelector from '@/components/service/sys_user_selector.vue'
import machineSelector from '@/components/service/machine_selector.vue'
import {mapState} from 'vuex'
export default {
	name: 'm_production_task_new',
	props: {
		id: String
	},
	components: {
		teamSelector,responsibleSelector,
		qualitySelector,machineSelector
	},
	computed: {
		...mapState(['systemConfig'])
	},
	data() {
		return {
			billtype_id:2,
			team_id:0,
			team_name: '',
			person_responsible_id: 0,
			person_responsible_name: '',
			order_info:{}
		}
	},

	watch: {
		id() {
			this.getOrderDetail();
			this.loadOrderTaskProduct();
		}
	},

	mounted() {
		this.getOrderDetail();
		this.loadOrderTaskProduct();
	},

	methods: {
		//查询订单详情
		getOrderDetail() {
			this.request({
				url: '/order/detail',
				data: {
					id:this.id
				},
				loading: false,
				success: data => {
					this.order_info = data;
				}
			});
		},
		//加载订单待派工产品
		loadOrderTaskProduct(){
			//加载待发货订单产品信息
			this.$refs.product_table.load({
				url: '/order/task/product/list',
				data: {id: this.id},
				dataFormatter: data => {
					data.forEach(item => {
						item.task_count = item.product_count;
					});
					return data;
				}
			});
		},
		//选择班组（弹框）
		selectTeam(){
			this.$refs.teamSelector.open();
		},
		//选择班组回调（弹框）
		selectTeamHandle(data){
			var obj = data[0];
			this.team_id = obj.id;
			this.$refs.form.set({
				team_id:obj.id,
				team_name:obj.team_name
			});
		},
		//选择班组（下拉）
		teamChangeOption(data){
			this.team_name = data.value
			this.team_id = data.id;
			this.person_responsible_id = undefined;
			this.person_responsible_name='';
		},
		//选择负责人
		selectResponsiblePerson(){
			/*if(!this.team_name){
				this.$message({
					message: '请先选择班组'
				});
				return;
			}
			this.$refs.responsibleSelector.open(this.team_id,'负责人，班组【'+this.team_name+'】',3);
			 */
			this.$refs.responsibleSelector.open(this.team_id,'负责人',3);
		},
		//选择负责人回调
		selectResponsibleHandle(data){
			var obj = data[0];
			this.$refs.form.set({
				person_responsible_id:obj.id,
				person_responsible_name:obj.user_realname
			});
		},
		//选择质检员
		selectQuality(){
			this.$refs.qualitySelector.open('','质检员');
		},
		//选择机台回调
		selectQualityHandle(data){
			var obj = data[0];
			this.$refs.form.set({
				quality_id:obj.id,
				quality_name:obj.user_realname
			});
		},
		//选择机台
		selectMachine(){
			this.$refs.machineSelector.open();
		},
		//选择机台回调
		selectMachineHandle(data){
			var obj = data[0];
			this.$refs.form.set({
				machine_name:obj.machine_name
			});
		},
		//打印
		printTask(task_number) {
			this.getApp().lodopPrint({
				billtype_id: this.billtype_id,
				number: task_number
			});
		},
		submit() {
			var data = this.$refs.product_table.getRow();
			var product_list = Array.from(data, item => item.data);
			var productList = [];
			//派工商品小计
			var total_product_count = 0;
			//校验数据
			this.$refs.form.submit({
				url:'/production/task/add',
				rule: {
					// team_name: '请选择班组',
					// machine_name: '请选择机台',
					person_responsible_id: '请选择负责员工',
					product_list: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							product_list.forEach(item => {
								var task_count = parseFloat(item.task_count||0);
								total_product_count = parseFloat(total_product_count) + task_count;
							});
							if(total_product_count <= 0){
								return false;
							}else{
								return true;
							}
						},
						message: '请至少输入一个产品的派工数量'
					}]
				},
				dataFormatter: data => {
					data.order_id = this.id;
					product_list.forEach(item => {
						if(item.task_count > 0){
							productList.push({
								id: item.id,
								task_count: item.task_count,
								
							});
						}
					});
					data.product_count = total_product_count;
					data.product_list = productList;
					return data;
				},
				success:(data,res) => {
					this.loadOrderTaskProduct();
					this.$bus.$emit('taskList.update', this.id ? undefined : 1);
					this.$bus.$emit('orderList.update',{});//刷新订单列表
					//是否打印
					this.$confirm({
						title: '温馨提示',
						message: '派工成功，是否现在打印任务单？',  // 提示内容,
						buttonName: '打印任务单',  // 确定按钮文字
						cancelButton: true,  // 是否显示取消按钮
						resolve: () => {
							this.printTask(res.task_number);
							//打开任务列表
							this.redirectTo('/task_list');
						},  // 确定按钮回调
						cancel: () => {
							//打开任务列表
							this.redirectTo('/task_list');
						},  // 取消按钮回调
					});
				},
			});
		}
	}
}
</script>